import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  CDN_IMAGE_PATH_FOR_NEWS,
  CDN_IMAGE_PATH_FOR_NEWS_IMAGE_NAME_IDENTIFIER,
  SHOW_AD_SECTIONS,
} from "../constants/EnvConstants";
import ImageComponent from "../components/ImageComponent";
import Header from "../components/landing/Header";
import News from "../components/News";
import { LandingRoute } from "../constants/RouteConstants";
import isObjectEmpty from "../functions/isObjectEmpty";

function NewsDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [news, setNews] = useState({});
  //   const { news } = location.state;

  useEffect(() => {
    if (location.state && location.state.news) {
      setNews(location.state.news);
    } else {
      navigate(LandingRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div
      style={{
        background: "linear-gradient(180deg, #212539 0%, #354070 100%) ",
      }}
      className="bg-black min-h-screen pb-[2em] "
    >
      <div className="-fixed top-0- w-full z-[10000] ">
        <Header selectedPage={"news"} />
      </div>
      {SHOW_AD_SECTIONS && (
        <div className="tablet:px-[10em] mobile:px-[6em] px-[1em]  pt-[6em] mobile:pt-[10em]">
          <div id="top-ad-newsdetail" className=" h-[10em] bg-slate-500"></div>
        </div>
      )}
      {isObjectEmpty(news) ? (
        <>Loading</>
      ) : (
        <div className="400 mobile:my-[5.7em] my-[4em] tablet:mx-[10em] mobile:mx-[6em] mx-[2em]">
          {" "}
          <h1 className=" text-[#FFFFFFE6] tablet:text-[3.4em] mobile:text-[2.5em] text-[1.8em]  font-[600]">
            {news.title}
          </h1>
          <p className="tablet:text-[1.4em] mobile:text-[1.3em] text-[1em] text-[#ffffffB3] tablet:mb-[3em] mb-[2em] mobile:mt-[0em] mt-[1em]">
            {new Date(news.createdTimestamp).toLocaleDateString()}
          </p>
          <ImageComponent
            className="rounded-xl w-full mobile:h-[540px]  h-[250px] !object-cover mb-4"
            name={news.imageFileName}
            CDN_IMAGE_PATH={CDN_IMAGE_PATH_FOR_NEWS}
            CDN_IMAGE_PATH_IMAGE_NAME_IDENTIFIER={
              CDN_IMAGE_PATH_FOR_NEWS_IMAGE_NAME_IDENTIFIER
            }
          />
          <p className="tablet:text-[1.8em] mobile:text-[1.6em] text-[1.4em] laptop:my-[3em] mobile:my-[2em] my-[1.5em] text-justify text-[#FFFFFF80]">
            {news.description}
          </p>
          <div className="h-[1px] bg-[#6C749933] mobile:mb-24 mb-12"></div>
          <div>
            <h1 className=" text-[#FFFFFFE6] tablet:text-[3.4em] mobile:text-[2.5em] text-[1.8em]  font-[600]">
              Related News
            </h1>
            <News
              hiddenVideoTitle={news.title}
              showTitle={-1}
              isViewAll={false}
              handleViewAll={null}
            />
          </div>
        </div>
      )}
      {SHOW_AD_SECTIONS && (
        <div className="tablet:px-[10em] mobile:px-[6em] px-[1em]  pt-[4em] mobile:pt-[6em]">
          <div
            id="bottom-ad-newsdetail"
            className=" h-[28em] bg-slate-500"
          ></div>
        </div>
      )}
    </div>
  );
}

export default NewsDetail;
