import React from "react";

function BallByBallScore({ ballByData, handleColorByBall }) {
  if (!Array.isArray(ballByData)) {
    return null;
  }
  return ballByData.map((over, i) => (
    <div className="flex" key={i}>
      <div className={`flex items-center mr-[2%] text-[14px] text-[#FFFFFF]`}>
        {over?.overName}
      </div>
      {Array.isArray(over?.scoreByBall) &&
        over?.scoreByBall.map((ball, j) => (
          <div
          key={j}
            className={`rounded-[10px] border-[1px]  px-[1.5em] py-[1em] text-[#FFFFFF] mr-[0.6em] flex items-center ${handleColorByBall(
              ball
            )}`}
          >
            {ball}
          </div>
        ))}
    </div>
  ));
}

export default BallByBallScore;
