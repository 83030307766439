export const BASE_URL = process.env.REACT_APP_API_URL;

export const GET_FIXTURES_ENDPOINT =
  process.env.REACT_APP_GET_FIXTURES_ENDPOINT;

export const GET_FIXTURE_BY_ID_ENDPOINT =
  process.env.REACT_APP_GET_FIXTURE_BY_ID_ENDPOINT;

export const GET_ALL_FIXTURES_ENDPOINT =
  process.env.REACT_APP_GET_ALL_FIXTURES_ENDPOINT;

export const GET_POINTS_TABLE_ENDPOINT =
  process.env.REACT_APP_GET_POINTS_TABLE_ENDPOINT;

export const ID_PARAM_IDENTIFIER = process.env.REACT_APP_ID_PARAM_IDENTIFIER;

export const GET_NEWS_ENDPOINT = process.env.REACT_APP_GET_NEWS_ENDPOINT;

export const GET_LIVE_STREAM_URL_BY_FIXTURE_ID_ENDPOINT =
  process.env.REACT_APP_GET_LIVE_STREAM_URL_BY_FIXTURE_ID_ENDPOINT;
export const GET_LIVE_STREAM_URL_BY_FIXTURE_ID_ENDPOINT_ID_PARAM_IDENTIFIER =
  process.env
    .REACT_APP_GET_LIVE_STREAM_URL_BY_FIXTURE_ID_ENDPOINT_ID_PARAM_IDENTIFIER;

export const GET_YOUTUBE_VIDEO_LINKS_ENDPOINT =
  process.env.REACT_APP_GET_YOUTUBE_VIDEO_LINKS_ENDPOINT;

export const GET_TOTAL_NO_OF_YOUTUBE_VIDEO_LINKS_ENDPOINT =
  process.env.REACT_APP_GET_TOTAL_NO_OF_YOUTUBE_VIDEO_LINKS_ENDPOINT;


export const GET_LIST_OF_SCHEDULE_CATEGORIES = process.env.React_APP_GET_SCHEDULE_CATEGORIES;

export const GET_LIST_OF_SCHEDULE_BY_CATEGORY_ID = process.env.React_APP_GET_SCHEDULE_BY_CATEGORY_ID;