import axios from "axios";
import { BASE_URL, GET_LIST_OF_SCHEDULE_BY_CATEGORY_ID } from "../constants/APIConstant";

const useGetAllScheduleByCategoryId = () => {
    const getAllScheduleByCategoryId = (categoryId, callback) => {
      axios
        .get(BASE_URL + GET_LIST_OF_SCHEDULE_BY_CATEGORY_ID + "categoryId=" + categoryId)
        .then((response) => {
          // callback(response?.data?.data);
        
            callback(response?.data?.data);
            // console.log("Response new api", response?.data?.data);
        })
        .catch((err) => {
          console.log(err);
          alert("Failed To Load Category Wise Schedules");
        });
    };
    return getAllScheduleByCategoryId;
  };
  
  export default useGetAllScheduleByCategoryId;