import React, { useContext, useEffect, useState } from "react";
import useGetAllSchedules from "../hooks/useGetAllSchedules";
import Fixture from "../components/Fixture";
import landingBackground from "../assets/images/landingBackground.jpg";
// import BackToHomeHeader from "../components/BackToHomeHeader";
import { appContext } from "../context/context";
import Header from "../components/landing/Header";
import useGetAllScheduleCategories from "../hooks/useGetAllScheduleCategories";
import useGetAllScheduleByCategoryId from "../hooks/useGetAllScheduleByCategoryId";
import { SHOW_AD_SECTIONS } from "../constants/EnvConstants";

const Schedule = () => {
  const appState = useContext(appContext);
  const setSelectedFixture = appState.setSelectedFixture;
  const getAllSchedules = useGetAllSchedules();
  // eslint-disable-next-line no-unused-vars
  const getAllScheduleCategories = useGetAllScheduleCategories();
  const [fixtures, setFixtures] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [schedules, setSchedules] = useState([]);

  const getAllScheduleByCategoryId =
    useGetAllScheduleByCategoryId(selectedCategory);

  const allSchedulesSessionStorageItemName = "all-fixtures-list";

  const setAllSchedulesCustom = (data) => {
    // Sort the data by scheduleDate and scheduledTime in ascending order
    const sortedData = data.sort((a, b) => {
      const dateA = new Date(`${a.scheduleDate}T${a.scheduledTime}`);
      const dateB = new Date(`${b.scheduleDate}T${b.scheduledTime}`);
      return dateA - dateB;
    });

    setFixtures(sortedData);
    // Convert data to JSON string
    const allFixturesString = JSON.stringify(sortedData);
    // Save to session storage
    sessionStorage.setItem(
      allSchedulesSessionStorageItemName,
      allFixturesString
    );
    setLoading(false);
  };

  useEffect(() => {
    const allFixturesString = sessionStorage.getItem(
      allSchedulesSessionStorageItemName
    );
    if (allFixturesString) {
      const newsDataArray = JSON.parse(allFixturesString);
      // Ensure the fixtures are sorted even if loaded from session storage
      const sortedData = newsDataArray.sort((a, b) => {
        const dateA = new Date(`${a.scheduleDate}T${a.scheduledTime}`);
        const dateB = new Date(`${b.scheduleDate}T${b.scheduledTime}`);
        return dateA - dateB;
      });
      setFixtures(sortedData);
      setLoading(false);
    } else {
      setLoading(true);
      getAllSchedules(setAllSchedulesCustom);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const updateCategories = (data) => {
    setCategories(data);
  };
  //get all
  useEffect(() => {
    // getAllScheduleCategories(updateCategories);
    // eslint-disable-next-line
  }, []);

  const onCategoryClicked = (categoryId) => {
    // console.log("Category clicked:", categoryId);
    setSelectedCategory(categoryId);
    setLoading(true);
    setFixtures([]);
  };

  useEffect(
    () => {
      if (selectedCategory === 0) {
        // console.log("Fetching all schedules for Category 0");
        getAllSchedules(setAllSchedulesCustom);
      } else {
        // console.log("Fetching schedules by Category ID:", selectedCategory);

        getAllScheduleByCategoryId(selectedCategory, (data) => {
          setFixtures(data); // Store data in state
          setLoading(false);
          // console.log("Fetched Data:", data); // Log data to console
        });
      }
    },
    // eslint-disable-next-line
    [selectedCategory]
  );

  return (
    <section
      className="bg-opacity-80 w-full min-h-screen pb-[5em]"
      style={{
        backgroundImage: `url(${landingBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center top",
        backgroundAttachment: "fixed", // Added to fix background in place
      }}
    >
      {/* <BackToHomeHeader title={"Schedules"} navigateBack={true} /> */}
      <div className="-fixed top-0 w-full z-[10000]">
        <Header selectedPage={"schedule"} />
      </div>
      {SHOW_AD_SECTIONS && (
        <div className="tablet:px-[10em] mobile:px-[6em] px-[1em]  pt-[6em] mobile:pt-[10em]">
          <div id="top-ad-schedule" className=" h-[10em] bg-slate-500"></div>
        </div>
      )}
      {false && (
        <div>
          {categories.length > 0 ? (
            <div className="flex whitespace-nowrap overflow-x-auto mt-10  tablet:mx-[12.8em] mx-[1.9em]">
              <div
                onClick={() => onCategoryClicked(0)}
                className={`text-xl mx-2 px-5 py-2 rounded-full cursor-pointer ${
                  selectedCategory === 0
                    ? "text-[#19AAF8] bg-[#39426b]"
                    : "text-white border-[0.1em] border-[#39426b]"
                }`}
              >
                ALL
              </div>

              {categories.map((category, i) => (
                <div
                  key={i}
                  onClick={() => onCategoryClicked(category.id)}
                  className={`text-xl mx-2 px-5 py-2 rounded-full cursor-pointer ${
                    category.id === selectedCategory
                      ? "text-[#19AAF8] bg-[#39426b]"
                      : "text-white border border-[#39426b]"
                  }`}
                >
                  {category.name}
                </div>
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center text-white mt-[10em] text-[2em]">
              Loading...
            </div>
          )}
        </div>
      )}

      {loading ? (
        <div className="flex justify-center items-center text-white mt-[10em] text-[2em]">
          Loading schedules...
        </div>
      ) : fixtures.length > 0 ? (
        <div
          className={
            "grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 text-[0.8em] tablet:text-[1em] rounded-xl tablet:mx-[12.8em] mx-[1.9em] mt-[3.6em] border-[1px] border-white "
          }
        >
          {fixtures.map((fixture, i) => (
            <Fixture
              key={i}
              fixture={fixture}
              isExpanded={true}
              isSelected={false}
              textColorClass={"text-[#ffffffE6]"}
              bgClass={i % 2 === 0 ? "bg-transparent" : "bg-[#ffffff08]"}
              className={"min-h-[18em]"}
              onClick={() => setSelectedFixture(fixture)}
            />
          ))}
        </div>
      ) : (
        <div className="flex justify-center items-center text-white mt-[10em] text-[2em]">
          No schedules available.
        </div>
      )}
      {SHOW_AD_SECTIONS && (
        <div className="tablet:px-[10em] mobile:px-[6em] px-[1em]  pt-[4em] mobile:pt-[6em]">
          <div id="bottom-ad-schedule" className=" h-[28em] bg-slate-500"></div>
        </div>
      )}
    </section>
  );
};

export default Schedule;
