import axios from "axios";
import { BASE_URL, GET_LIST_OF_SCHEDULE_CATEGORIES } from "../constants/APIConstant";

const useGetAllScheduleCategories = () => {
    const getAllScheduleCategories = (callback) => {
      axios
        .get(BASE_URL + GET_LIST_OF_SCHEDULE_CATEGORIES)
        .then((response) => {
          // callback(response?.data?.data);
          if (Array.isArray(response?.data?.data)) {
            callback(response?.data?.data);
            // console.log("Response all cateories api", response);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Failed To Load Categories");
        });
    };
    return getAllScheduleCategories;
  };
  
  export default useGetAllScheduleCategories;
  