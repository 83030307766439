import React, { useContext } from "react";
import landingBackground from "../assets/images/landing-page.png";
import Squads from "./Squads";
import getDateWithDay from "../functions/getDateWithDay";
import { appContext } from "../context/context";
import getRelativeDate from "../functions/getRelativeDate";
import getFormattedTime from "../functions/getFormattedTime";
import FIXTURE_CONSTANTS from "../constants/FixtureConstants";
import isObjectEmpty from "../functions/isObjectEmpty";
import MatchInfo from "./MatchInfo";
import AudioPlayer from "../reusable/AudioPlayer";
import ImageComponent from "./ImageComponent";
import { SHOW_AD_SECTIONS } from "../constants/EnvConstants";

const HomeOutlet = () => {
  const appState = useContext(appContext);
  const fixture = appState.selectedFixtureDetailed;
  const selectedFixtureShort = appState.selectedFixture;
  const loadingFixtureDetailed = appState.loadingFixtureDetailed;
  const streamingUrl = appState.liveStreamUrl;

  const matchIsScheduled =
    fixture?.status === FIXTURE_CONSTANTS.FIXTURE_STATUS.SCHEDULED ||
    fixture.status ===
      FIXTURE_CONSTANTS.FIXTURE_STATUS.SCHEDULED_SQUAD_SELECTED;
  const matchInProgress =
    fixture?.status === FIXTURE_CONSTANTS.FIXTURE_STATUS.IN_PROGRESS;
  const matchIsCompleted =
    fixture?.status === FIXTURE_CONSTANTS.FIXTURE_STATUS.COMPLETED;

  const teams = Array.isArray(fixture?.teamsDetails)
    ? fixture?.teamsDetails
    : [];

  return (
    <section
      className="min-h-screen bg-opacity-80 w-full pt-[9.2em] pb-[2em] "
      style={{
        backgroundImage: `url(${landingBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "top",
      }}
    >
      {isObjectEmpty(fixture) && isObjectEmpty(selectedFixtureShort) ? (
        <div className="flex justify-center items-center text-white mt-[10em] mobile:text-[2em] text-[1.3em]">
          No Fixture Selected
        </div>
      ) : fixture?.id !== selectedFixtureShort?.id || loadingFixtureDetailed ? (
        <div className="flex justify-center items-center text-white mt-[10em] mobile:text-[2em] text-[1.3em]">
          Loading...
        </div>
      ) : (
        <div className="mb-[4.6em] mobile:px-[3.8em] px-[2.0em] ">
          <div className=" px-[2.0em]">
            <h1 className="text-[#6C7499] text-[1.4em] mb-[3.2em] text-center">
              {fixture?.seriesName ? fixture?.seriesName + " - " : ""}
              {getDateWithDay(fixture?.scheduleDate)}
            </h1>

            <div className="grid grid-cols-2 gap-x-[10vw]  tablet:px-[13.1em]">
              {teams.map((team, i) => (
                <TeamInfo
                  key={i}
                  team={team}
                  reversed={i === 1}
                  showScore={matchInProgress || matchIsCompleted}
                />
              ))}
            </div>
            <div className="text-center text-[#FFFFFFE6] tablet:text-[1.8em] text-[1.4em] mt-[2.4em]">
              <h1 className="font-[600]">
                {matchIsScheduled && getRelativeDate(fixture?.scheduleDate)}
                {matchInProgress && ""}
                {matchIsCompleted && fixture?.matchResult}
              </h1>
              <h1 className="font-[300]">
                {matchIsScheduled && getFormattedTime(fixture?.scheduledTime)}
                {matchInProgress && ""}
                {matchIsCompleted &&
                  fixture?.manOfMatchName &&
                  `Man of the Match - ${fixture?.manOfMatchName}`}
              </h1>
              <h1 className="text-[#FFFFFF80] mt-[1.5em]">{fixture?.title}</h1>
            </div>
          </div>
          {matchInProgress && streamingUrl?.isActive && (
            <div className="mb-16 flex flex-col items-center space-y-[2.5em]">
              <h1 className="text-center tablet:text-[1.8em] text-[1.4em]  text-[#FFFFFF80] mt-[1.5em]">
                Live Commentary
              </h1>
              <AudioPlayer link={streamingUrl?.liveUrl} />
            </div>
          )}
          {SHOW_AD_SECTIONS && (
            <div className="  pt-[3em] mobile:pt-[5em] pb-[3em] mobile:pb-[5em] ">
              <div
                id="top-ad-fixtures"
                className=" h-[10em] bg-slate-500"
              ></div>
            </div>
          )}
          <div className="pt-10 ">
            {matchIsScheduled && <Squads />}
            {(matchInProgress || matchIsCompleted) && <MatchInfo />}
          </div>
        </div>
      )}
      {SHOW_AD_SECTIONS && (
        <div className="tablet:px-[5em] mobile:px-[3em] px-[1em]  ">
          <div id="bottom-ad-fixtures" className=" h-[28em] bg-slate-500"></div>
        </div>
      )}
    </section>
  );
};

const TeamInfo = ({ team, reversed, showScore }) => {
  const inningList = team?.inningDetails;
  return (
    <div
      className={`flex items-center justify-between ${
        reversed && "flex-row-reverse"
      }`}
    >
      <div className="text-center space-y-[1.2em] flex flex-col items-center">
        <div className="w-[5.4em] h-[3.6em] flex items-center justify-center overflow-hidden">
          <ImageComponent
            className={"rounded-[5px] object-cover"}
            name={team?.flagImageName}
            style={{ width: "5.4em", height: "3.6em" }}
          />
        </div>
        <h1 className="text-[1.6em] text-[#FFFFFFE6] font-[550] ">
          {team?.teamName}
        </h1>
      </div>
      <div className="space-y-5">
        {showScore &&
          inningList &&
          Array.isArray(inningList) &&
          inningList.map((inning, index) => (
            <div key={index}>
              <div className="text-center">
                <h1 className="font-[700] laptop:text-[2em]  text-[1.8em] text-[#FFFFFFE6] ">
                  {inning?.actualTotalRuns || inning?.totalRuns || "0"}
                  {(inning?.wicketsLost || inning?.wicketsLost === 0) &&
                  inning?.wicketsLost < 10
                    ? `/${inning?.wicketsLost}`
                    : ""}
                </h1>
                <h1 className=" laptop:text-[1.4em] tablet:text-[1.2em] text-[1em] text-[#e5e5e5]">
                  ({inning?.oversBowled || "0"}
                  {inning?.ballsBowled && inning?.ballsBowled > 0
                    ? `.${inning?.ballsBowled}`
                    : ""}
                  )
                </h1>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default HomeOutlet;
