import React from "react";
// import BackToHomeHeader from "../components/BackToHomeHeader";
import VideosComponent from "../components/Videos";
import Header from "../components/landing/Header";
import { SHOW_AD_SECTIONS } from "../constants/EnvConstants";

function Videos() {
  return (
    <div
      style={{
        background: "linear-gradient(180deg, #212539 0%, #354070 100%)",
      }}
      className=" min-h-screen pb-[2em] "
    >
      {/* <BackToHomeHeader title={"Videos"} navigateBack={true} /> */}
      <div className="-fixed top-0- w-full z-[10000] ">
        <Header selectedPage={"videos"} />
      </div>
      {SHOW_AD_SECTIONS && (
        <div className="tablet:px-[10em] mobile:px-[6em] px-[1em]  pt-[3em] mobile:pt-[5em] pb-[3em] mobile:pb-[5em]">
          <div id="top-ad-videos" className=" h-[10em] bg-slate-500"></div>
        </div>
      )}
      <VideosComponent />
      {SHOW_AD_SECTIONS && (
        <div className="tablet:px-[10em] mobile:px-[6em] px-[1em]  pt-[4em] mobile:pt-[6em]">
          <div id="bottom-ad-videos" className=" h-[28em] bg-slate-500"></div>
        </div>
      )}
    </div>
  );
}

export default Videos;
