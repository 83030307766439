import React, { useContext, useState } from "react";
import MatchStats from "./MatchStats";
import { appContext } from "../context/context";
import BtnContainer from "../reusable/BtnContainer";
import FixtureConstants from "../constants/FixtureConstants";
import LightedSection from "./LightedSection";

const MatchInfo = () => {
  const appState = useContext(appContext);
  const fixture = appState.selectedFixtureDetailed;

  const [overviewSectionVisible, setOverviewSectionVisible] = useState(true);
  const [selectedTeamNo, setSelectedTeamNo] = useState(0);

  const selectFirstTeam = () => {
    setSelectedTeamNo(0);
  };
  const selectSecondTeam = () => {
    setSelectedTeamNo(1);
  };
  const showOverview = () => {
    setOverviewSectionVisible(true);
  };
  const showScoreCard = () => {
    setOverviewSectionVisible(false);
  };

  const menuSection = (
    <div className="space-y-[0.8em]">
      <BtnContainer>
        <div className="grid grid-cols-2">
          <button
            onClick={showOverview}
            className={`mobile:text-[14px] text-[12px]${
              overviewSectionVisible && "text-[#ffffff4D] pointer-events-none"
            }`}
          >
            Overview
          </button>
          <button
            onClick={showScoreCard}
            className={`mobile:text-[14px] text-[12px] ${
              !overviewSectionVisible && "text-[#ffffff4D] pointer-events-none"
            }`}
          >
            Score Card
          </button>
        </div>
      </BtnContainer>
      {!overviewSectionVisible && (
        <div className="flex items-center justify-center w-full  mt-[3.1em] space-x-4">
          <BtnContainer
            className={"cursor-pointer"}
            onClick={selectFirstTeam}
            disabled={selectedTeamNo === 0}
            isClickable={true}
          >
            {fixture &&
              Array.isArray(fixture?.teamsDetails) &&
              fixture?.teamsDetails[0]?.teamName}
          </BtnContainer>
          <BtnContainer
            className={"cursor-pointer"}
            onClick={selectSecondTeam}
            disabled={selectedTeamNo === 1}
            isClickable={true}
          >
            {fixture &&
              Array.isArray(fixture?.teamsDetails) &&
              fixture?.teamsDetails[1]?.teamName}
          </BtnContainer>
        </div>
      )}
    </div>
  );

  const battingInfoHeaders = [
    {
      headerName: "Batting",
      className: "col-span-4 tablet:col-span-5 text-left pl-5",
    },
    {
      headerName: "R",
    },
    {
      headerName: "B",
    },
    {
      headerName: "4s",
    },
    {
      headerName: "6s",
    },
    {
      headerName: "SR",
      className: "col-span-2 tablet:col-span-1",
    },
  ];
  const bowlingInfoHeaders = [
    {
      headerName: "Bowling",
      className: "col-span-4 tablet:col-span-5 text-left pl-5",
    },
    {
      headerName: "O",
    },
    {
      headerName: "R",
    },
    {
      headerName: "M",
    },
    {
      headerName: "W",
    },
    {
      headerName: "Econ",
      className: "col-span-2 tablet:col-span-1",
    },
  ];

  const getPlayerDataFromSquads = (id) => {
    for (let i = 0; i < fixture?.teamsDetails.length; i++) {
      const team = fixture?.teamsDetails[i];
      for (let j = 0; j < team?.squad.length; j++) {
        const squadPlayer = team?.squad[j];
        if (squadPlayer?.playerId === id) {
          return squadPlayer;
        }
      }
    }
    return {};
  };

  const inningsList = Array.isArray(fixture?.teamsDetails)
    ? fixture?.teamsDetails[selectedTeamNo]?.inningDetails
    : null;

  const getActiveInnings = () => {
    const activeTeamId = fixture?.teamIdWithActiveInning;
    const activeTeamInnings =
      fixture?.teamsDetails[0]?.teamId === activeTeamId
        ? fixture?.teamsDetails[0].inningDetails
        : fixture?.teamsDetails[1].inningDetails;
    return Array.isArray(activeTeamInnings)
      ? activeTeamInnings[activeTeamInnings.length - 1]
      : activeTeamInnings;
  };
  const activeInnings = getActiveInnings();

  const getActiveBatsmenAndBowlers = () => {
    const activeBatsmen = [];
    const activeBowlers = [];

    const firstBatsManId = activeInnings?.strikerPlayerId;
    const secondBatsManId = activeInnings?.nonStrikerPlayerId;
    const currentBowlerId = activeInnings?.currentBowlerId;
    const prevBowlerId = activeInnings?.previousBowlerId;

    if (firstBatsManId || secondBatsManId) {
      for (
        let index = 0;
        index < activeInnings?.battingRecord.length;
        index++
      ) {
        const batsman = activeInnings?.battingRecord[index];
        if (
          batsman?.batsmanId === firstBatsManId ||
          batsman?.batsmanId === secondBatsManId
        ) {
          activeBatsmen.push(batsman);
        }
      }
    }

    if (currentBowlerId || prevBowlerId) {
      for (
        let index = 0;
        index < activeInnings?.bowlingRecord.length;
        index++
      ) {
        const bowler = activeInnings?.bowlingRecord[index];
        if (
          bowler?.bowlerId === currentBowlerId ||
          bowler?.bowlerId === prevBowlerId
        ) {
          activeBowlers.push(bowler);
        }
      }
    }
    return {
      batsmen: activeBatsmen,
      bowlers: activeBowlers,
      strikerId: firstBatsManId,
    };
  };

  const activeBatsmenAndBowlers = getActiveBatsmenAndBowlers();

  const getBatsmanInfo = (player, i, isStriker) => {
    const playerDetails = getPlayerDataFromSquads(player?.batsmanId);
    const battingStatus = player?.battingStatus;
    const batsmanIsNotOut =
      battingStatus === FixtureConstants.BATSMAN_BATTING_STATUS.NOT_OUT;
    const batsmanIsOutByBowled =
      battingStatus === FixtureConstants.BATSMAN_BATTING_STATUS.BOWLED;
    const batsmanIsOutByRunOut =
      battingStatus === FixtureConstants.BATSMAN_BATTING_STATUS.RUN_OUT;
    const batsmanIsOutByCatchOut =
      battingStatus === FixtureConstants.BATSMAN_BATTING_STATUS.CATCH_OUT;
    const batsmanIsOutByLBW =
      battingStatus === FixtureConstants.BATSMAN_BATTING_STATUS.LBW;
    const batsmanIsOutByHitWicket =
      battingStatus === FixtureConstants.BATSMAN_BATTING_STATUS.HIT_WICKET;
    return (
      <div
        key={i}
        className={`grid grid-cols-10 tablet:text-[1.6em] mobile:text-[1.4em]  text-[1.1em] text-center text-white ${
          i % 2 !== 0 && "rounded-[10px] bg-[#FFFFFF1A]"
        }`}
      >
        <div className={`py-[2em] ${battingInfoHeaders[0]?.className || ""}`}>
          <div>
            {player?.batsmanName}
            {batsmanIsNotOut && isStriker && " * "}
            {playerDetails?.isWicketKeeper ===
              FixtureConstants.WICKET_KEEPER_ROLE.TRUE && " (WK)"}
            {playerDetails?.role === FixtureConstants.PLAYER_ROLE.CAPTIAN &&
              " (C)"}
          </div>
          <div className="text-[#ffffff80]">
            {batsmanIsOutByBowled && `b ${player?.dismissingBowlerName}`}
            {batsmanIsOutByRunOut &&
              `Run out ${
                player?.dismissingPlayer2Name || player?.dismissingBowlerName
              }`}
            {batsmanIsOutByCatchOut &&
              `${
                player?.dismissingPlayer2Name
                  ? `c ${player?.dismissingPlayer2Name}`
                  : `c &`
              } b ${player?.dismissingBowlerName}`}
            {batsmanIsOutByLBW && `lbw ${player?.dismissingBowlerName}`}
            {batsmanIsOutByHitWicket &&
              `Hit Wicket b ${player?.dismissingBowlerName}`}
          </div>
        </div>
        <div className={`py-[2em] ${battingInfoHeaders[1]?.className || ""}`}>
          {player?.runsScored}
        </div>
        <div className={`py-[2em] ${battingInfoHeaders[2]?.className || ""}`}>
          {player?.ballsFaced}
        </div>
        <div className={`py-[2em] ${battingInfoHeaders[3]?.className || ""}`}>
          {player?.boundriesFours}
        </div>
        <div className={`py-[2em] ${battingInfoHeaders[4]?.className || ""}`}>
          {player?.boundriesSixes}
        </div>
        <div className={`py-[2em] ${battingInfoHeaders[5]?.className || ""}`}>
          {player?.ballsFaced > 0
            ? ((player?.runsScored * 100) / player?.ballsFaced).toFixed(2)
            : 0}
        </div>
      </div>
    );
  };

  const getBowlersInfo = (bowler, i) => {
    const totalBallsBowled =
      (bowler?.oversBowled || 0) * 6 + (bowler?.ballsBowled || 0);
    const totalRunsGiven = bowler?.runsGiven;
    const runsGivenPerBall =
      totalBallsBowled > 0 ? totalRunsGiven / totalBallsBowled : 0;
    const economy = (runsGivenPerBall * 6).toFixed(2);
    return (
      <div
        key={i}
        className={`grid grid-cols-10 tablet:text-[1.6em] mobile:text-[1.4em]  text-[1.1em] text-center mobile:mt-[0px] mt-[10px] text-white  ${
          i % 2 !== 0 && "rounded-[8px] bg-[#FFFFFF1A]"
        }`}
      >
        <div className={`py-[1em] ${bowlingInfoHeaders[0]?.className || ""}`}>
          <div>{bowler?.bowlerName}</div>
          <div>{}</div>
        </div>
        <div className={`py-[1em] ${bowlingInfoHeaders[1]?.className || ""}`}>
          {bowler?.oversBowled}
          {bowler?.ballsBowled > 0 ? `.${bowler.ballsBowled}` : ""}
        </div>
        <div className={`py-[1em] ${bowlingInfoHeaders[2]?.className || ""}`}>
          {bowler?.runsGiven}
        </div>
        <div className={`py-[1em] ${bowlingInfoHeaders[3]?.className || ""}`}>
          {bowler?.maidens}
        </div>
        <div className={`py-[1em] ${bowlingInfoHeaders[4]?.className || ""}`}>
          {bowler?.wicketsTaken}
        </div>
        <div className={`py-[1em] ${bowlingInfoHeaders[5]?.className || ""}`}>
          {economy}
        </div>
      </div>
    );
  };

  const getTossWinnerTeamName = () => {
    const tossWinnerTeamId = fixture?.tossWinnerTeamId;
    for (let index = 0; index < fixture?.teamsDetails.length; index++) {
      const team = fixture?.teamsDetails[index];
      if (team?.teamId === tossWinnerTeamId) {
        return team?.teamName;
      }
    }
    return "";
  };

  const tossWinnerTeamName = getTossWinnerTeamName();

  const overViewSection = (
    <div>
      <div
        className={
          " bg-[#FFFFFF1A] rounded-xl tablet:px-[2.2em] px-[1.2em] pt-[3em] pb-[3em] border-cls "
        }
      >
        <div className="space-y-20 mx-10">
          {activeBatsmenAndBowlers.batsmen.length > 0 && (
            <div>
              <div className="grid grid-cols-10 tablet:text-[1.6em] mobile:text-[1.4em] text-[1.2em]  font-[300] text-center text-white border-b-[#FFFFFF1A] border-b-[1px] pb-4">
                {battingInfoHeaders.map((header, i) => (
                  <div key={i} className={`${header.className || ""}`}>
                    {header.headerName}
                  </div>
                ))}
              </div>
              {activeBatsmenAndBowlers.batsmen.map((player, i) =>
                getBatsmanInfo(
                  player,
                  i,
                  activeBatsmenAndBowlers?.strikerId === player.batsmanId
                )
              )}
            </div>
          )}
          {activeBatsmenAndBowlers.bowlers.length > 0 && (
            <div>
              <div className="grid grid-cols-10 tablet:text-[1.6em] mobile:text-[1.4em] text-[1.2em]  font-[300] text-center text-white border-b-[#FFFFFF1A] border-b-[1px] pb-4">
                {bowlingInfoHeaders.map((header, i) => (
                  <div key={i} className={`${header.className || ""}`}>
                    {header.headerName}
                  </div>
                ))}
              </div>
              {activeBatsmenAndBowlers.bowlers.map((bowler, i) =>
                getBowlersInfo(bowler, i)
              )}
            </div>
          )}
          <div
            className={`${
              activeBatsmenAndBowlers.batsmen.length > 0 ||
              activeBatsmenAndBowlers.bowlers.length > 0
                ? "border-t-[1px] border-t-[#ffffff1A]"
                : ""
            } py-[2.7em] space-y-[0.7em]`}
          >
            {tossWinnerTeamName.length > 0 ? (
              <p className="text-center mobile:text-[1.6em] text-[1.3em] text-[#ffffff66]">
                <span className="text-[#19AAF8]">Toss: </span>
                {tossWinnerTeamName} won the toss and decided to{" "}
                {fixture?.tossDecision === 1 ? "bat" : "field"}
              </p>
            ) : (
              <></>
            )}
            <p className="text-center mobile:text-[1.6em] text-[1.3em] text-[#ffffff66]">
              <span className="text-[#19AAF8]">Stadium: </span>
              {fixture?.groundName}
            </p>
          </div>
        </div>
      </div>
      <MatchStats innings={activeInnings} />
    </div>
  );
  const scoreCardSection =
    inningsList && Array.isArray(inningsList) ? (
      inningsList.map((innings, index) => {
        const fallofWickets =
          innings?.fallOfWickets && Array.isArray(innings?.fallOfWickets)
            ? innings?.fallOfWickets
            : [];
        return (
          <div key={index} className="space-y-[4em] mx-4">
            {/* Batting Info */}
            <div className="space-y-[2.6em]">
              <p className="font-[600] mobile:text-[1.8em] text-[1.3em]  text-[#fff]">
                {Array.isArray(fixture?.teamsDetails) &&
                  `${fixture?.teamsDetails[selectedTeamNo]?.teamName} ${innings?.totalRuns}/${innings?.wicketsLost} in ${innings?.oversBowled}.${innings?.ballsBowled}  overs`}
              </p>
              <div
                className={
                  " bg-[#FFFFFF1A] rounded-xl tablet:px-[2.2em] px-[1.2em] pt-[3em] pb-[3em] border-cls "
                }
              >
                <div className="grid grid-cols-10 tablet:text-[1.6em] mobile:text-[1.4em] text-[1.2em]  font-[300] text-center text-white border-b-[#FFFFFF1A] border-b-[1px] pb-4">
                  {battingInfoHeaders.map((header, i) => (
                    <div key={i} className={`${header.className || ""}`}>
                      {header.headerName}
                    </div>
                  ))}
                </div>
                {innings &&
                  innings?.battingRecord &&
                  Array.isArray(innings?.battingRecord) &&
                  innings?.battingRecord.map((player, i) =>
                    getBatsmanInfo(player, i, true)
                  )}
              </div>
              <p className="text-[#ffffffE6] mobile:text-[1.6em] text-[1.3em] ">
                Extras: {innings?.extraWideBalls}w, {innings?.extraNoBalls}n,{" "}
                {innings?.extraByes}b, {innings?.extraLegByes}lb, {innings?.extraPenaltyRuns}pen
              </p>
            </div>
            {/* Fall Of Wickets Info */}
            {fallofWickets.length > 0 && (
              <div className="space-y-[2.6em]">
                <p className="font-[600] mobile:text-[1.8em] text-[1.3em]  text-[#fff]">
                  Fall of wickets
                </p>
                <div
                  className={
                    " bg-[#FFFFFF1A] rounded-xl tablet:px-[2.2em] px-[1.2em] pt-[3em] pb-[3em] border-cls "
                  }
                >
                  <div className="grid tablet:grid-cols-5 gap-y-[2.2em]">
                    {fallofWickets.map((wkt, i) => (
                      <div
                        key={i}
                        className={`${
                          (i + 1) % 3 !== 0 && "tablet:col-span-2"
                        }`}
                      >
                        <p className="text-white  mobile:text-[1.6em] text-[1.2em] ">
                          <span className="opacity-90">
                            {wkt?.dismissedBatsmanName}{" "}
                          </span>
                          <span className="opacity-70 font-[300]">
                            {" "}
                            {wkt?.totalRunsAtDismissal} -{" "}
                            {wkt?.dismissedOverNum}.{wkt?.dismissedBallNum}ov
                          </span>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {/* Bowling Info */}
            <div className="space-y-[2.6em]">
              <p className="font-[600] mobile:text-[1.8em] text-[1.3em]  text-[#fff]">
                {Array.isArray(fixture?.teamsDetails) &&
                  `${fixture?.teamsDetails[selectedTeamNo]?.teamName} ${innings?.totalRuns}/${innings?.wicketsLost} in ${innings?.oversBowled}.${innings?.ballsBowled}  overs`}
              </p>
              <div
                className={
                  " bg-[#FFFFFF1A] rounded-xl tablet:px-[2.2em] px-[1.2em] pt-[3em] pb-[3em] border-cls "
                }
              >
                <div className="grid grid-cols-10 tablet:text-[1.6em] mobile:text-[1.4em] text-[1.2em] font-[300] text-center text-white border-b-[#FFFFFF1A] border-b-[1px] pb-4">
                  {bowlingInfoHeaders.map((header, i) => (
                    <div key={i} className={`${header.className || ""}`}>
                      {header.headerName}
                    </div>
                  ))}
                </div>
                {innings &&
                  innings?.bowlingRecord &&
                  Array.isArray(innings?.bowlingRecord) &&
                  innings?.bowlingRecord.map((bowler, i) =>
                    getBowlersInfo(bowler, i)
                  )}
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <LightedSection>
        <div className="flex justify-center items-center text-[#ffffff80] my-[3em] mobile:text-[2em] text-[1.3em] font-[500]">
          Innings not started...
        </div>
      </LightedSection>
    );
  return (
    <div className="space-y-[3.3em]">
      {menuSection}
      {overviewSectionVisible ? overViewSection : scoreCardSection}
    </div>
  );
};

export default MatchInfo;
